// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ng-primary: mat-palette($mat-indigo);
$ng-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ng-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ng-theme: mat-light-theme(
  (
    color: (
      primary: $ng-primary,
      accent: $ng-accent,
      warn: $ng-warn,
    ),
  )
);

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ng-theme);

// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

@media screen and (max-width: 600px) {
  .breadcrumb {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
  .navbar-brand {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
  .date-time {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
.mat-column-select {
  overflow: initial;
}

.mat-row:hover .mat-cell {
  background-color: #48bfeb;
  color: white;
  cursor: pointer;
}
.row-is-clicked {
  font-weight: bold;
}
.mat-row.row-is-error {
  background-color: #eb8989;
}

.row-is-success {
  background-color: #91f8a8;
}
.mat-row.row-is-error:hover .mat-cell {
  background-color: #9e0b0b !important;
  color: white;
  cursor: pointer;
}
.refresh-btn {
  color: white;
}
.refresh-btn:hover {
  color: black;
}

.refresh-rotate {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.blur-back {
  filter: blur(2px);
}

.spiner-loading {
  color: white;
  font-size: x-large;
  width: 4rem;
  height: 4rem;
  z-index: 99999 !important;
  position: fixed;
  top: 50%;
  left: 50%;
}
.back-drop-loading {
  background-color: black;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9998 !important;
  top: 0;
  left: 0;
  opacity: 0.4;
}
.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 1.3s ease-out infinite;
}
.zoom-in-zoom-out-once{
  // animation-name: zoom-in-zoom-out-once;
  // animation-duration: 4s;
  animation: zoom-in-zoom-out-once 1.5s ease-out;
}

@keyframes zoom-in-zoom-out-once {
  0% {
    transform: scale(0.5, 0.5);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
