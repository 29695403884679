// Variable overrides
$sidebar-bg:white;
$sidebar-nav-link-icon-color:#191919;
$sidebar-nav-link-color:#191919;

$sidebar-minimizer-bg:f0f3f5;


$theme-colors: (
  "bg-info":#d3f2fb
);
$navbar-color:#191919;
