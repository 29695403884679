// Here you can add other styles
body{
  background-color: whitesmoke;
  // background-image: url("/src/assets/img/bg/prison-bg.jpg");
  // width: 100vw;
  // height: 100%;
  // opacity: 0.6;
  // background-attachment: fixed;
  // background-repeat: no-repeat;
}

h1,h2,h3 {
  margin: 0 !important;
}

table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}
.cdk-global-scrollblock {
  position: static !important;
}
.bg-info {
  background-color: #d3f2fb !important;
}
@import '@angular/material/theming';
$custom-typography: mat-typography-config(
  $font-family: 'sans-serif'
);
@include mat-core($custom-typography);




// .mat-row .mat-cell {
//   border-bottom: 1px solid transparent;
//   border-top: 1px solid transparent;
//   cursor: pointer;
// }

// .mat-row:hover .mat-cell {
//   border-color: black;
// }


thead {
  background-color: #f0f3f5 !important;
}


